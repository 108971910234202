import Vue from "vue";
import Centrifuge from "centrifuge";
import store from "@/store";

let centrifuge = new Centrifuge(
  `wss://c.${
    process.env.VUE_APP_CENTRIFUGE_DOMAIN || document.location.host
  }/connection/websocket`
);

Vue.prototype.$centrifuge = centrifuge;

const connect = (token = process.env.VUE_APP_CENTRIFUGE_TOKEN) => {
  centrifuge.setToken(token);
  centrifuge.connect();
};

store.watch(
  () => store.state.user.isLoaded,
  () => {
    connect(store.state.user.user?.cent_token);
    // Проверяем обновление токена
    store.watch(
      () => store.state.user.user?.cent_token,
      (token) => {
        centrifuge.disconnect();
        connect(token);
      }
    );
  }
);
