<template>
  <form @submit.prevent="register" class="form">
    <div class="group">
      <input
        type="text"
        required
        v-model="form.login"
        placeholder="ВАШ ЛОГИН"
      />
    </div>
    <span v-if="errors.login" class="text-danger">{{ errors.login }}</span>
    <div class="group">
      <input
        type="email"
        required
        v-model="form.email"
        placeholder="ВАШ EMAIL"
      />
    </div>
    <span v-if="errors.email" class="text-danger">{{ errors.email }}</span>
    <div class="group">
      <input
        type="password"
        required
        v-model="form.password"
        placeholder="ВАШ ПАРОЛЬ"
      />
      <img alt="help" src="@/assets/icons/help-icon.svg" />
    </div>
    <span v-if="errors.password" class="text-danger">{{
      errors.password
    }}</span>
    <div class="group">
      <input
        type="password"
        required
        v-model="form.password_confirmation"
        placeholder="ПОВТОРИТЕ ПАРОЛЬ"
      />
    </div>
    <button class="auth">Создать аккаунт</button>
  </form>
</template>

<script>
export default {
  data() {
    return {
      form: {
        login: "",
        email: "",
        password: "",
        password_confirmation: "",
      },
      errors: {
        login: null,
        email: null,
        password: null,
      },
    };
  },
  methods: {
    async register() {
      this.errors = {
        login: null,
        email: null,
        password: null,
      };

      try {
        let form = this.form;
        const refCode = localStorage.getItem("refCode") || null;
        if (refCode) form.refCode = refCode;
        const fp = await this.$store.dispatch("user/getFingerprint");
        form.fp = fp;
        const { data } = await this.$http.post("auth/register", form);
        let { access_token, user } = data.response;
        this.$store.dispatch("user/login", { access_token, user });
      } catch (error) {
        // Ошибка валидации
        if (error.status === 400) {
          for (let key in error.data.error) {
            this.errors[key] = error.data.error[key][0];
          }
        } else this.errors["password"] = "Ошибка сервера, попробуйте позднее.";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@use "sass:math";
// Группа формы
.group {
  position: relative;
  margin-bottom: 15px;
  img {
    position: absolute;
    right: 20px;
    top: math.div(65px - 19px, 2);
    transition: 200ms ease;
    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }
  input {
    background: #ffffff;
    box-shadow: 0px 25px 35px rgba(226, 227, 243, 0.65);
    border-radius: 4px;
    width: 100%;
    height: 65px;
    padding: 0 25px;
    font-weight: 500;
    font-size: 16px;
    &::placeholder {
      color: #d2d2d2;
    }
  }
}
// Кнопка действия / Вход / Регистрация
button.auth {
  background: #0085ff;
  box-shadow: 0px 15px 15px rgba(0, 133, 255, 0.25);
  border-radius: 6px;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 18px;
  font-weight: 500;
  transition: 200ms ease;
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
}

.text-danger {
  display: block;
  margin-bottom: 15px;
  color: #f4511e;
  font-size: 15px;
  font-weight: 500;
}
</style>
