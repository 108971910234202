<template>
  <form @submit.prevent="login" class="form">
    <div class="group">
      <input
        type="text"
        v-model="form.login"
        required
        placeholder="ВАШ ЛОГИН"
      />
    </div>
    <div class="group">
      <input
        type="password"
        v-model="form.password"
        required
        placeholder="ВАШ ПАРОЛЬ"
      />
      <img alt="help" src="@/assets/icons/help-icon.svg" />
    </div>
    <span v-if="error" class="text-danger">{{ error }}</span>
    <button class="auth" type="submit">Войти</button>
  </form>
</template>

<script>
export default {
  data() {
    return {
      form: {
        login: "",
        password: "",
      },
      error: null,
    };
  },
  methods: {
    async login() {
      this.error = null;
      try {
        const fp = await this.$store.dispatch("user/getFingerprint");
        const { data } = await this.$http.post("auth/login", {
          ...this.form,
          fp,
        });
        let { access_token } = data.response;
        this.$store.dispatch("user/login", { access_token });
      } catch (error) {
        // Ошибка валидации
        if (error?.status === 400) this.error = "Заполните все поля";
        else if (error?.status === 401)
          this.error = "Некорректный логин или пароль";
        else this.error = "Ошибка сервера, попробуйте позднее.";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@use "sass:math";
// Группа формы
.group {
  position: relative;
  margin-bottom: 15px;
  img {
    position: absolute;
    right: 20px;
    top: math.div(65px - 19px, 2);
    transition: 200ms ease;
    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }
  input {
    background: #ffffff;
    box-shadow: 0px 25px 35px rgba(226, 227, 243, 0.65);
    border-radius: 6px;
    width: 100%;
    height: 65px;
    padding: 0 25px;
    font-weight: 500;
    font-size: 16px;
    &::placeholder {
      color: #d2d2d2;
    }
  }
}
// Кнопка действия / Вход / Регистрация
button.auth {
  background: #0085ff;
  box-shadow: 0px 15px 15px rgba(0, 133, 255, 0.25);
  border-radius: 6px;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 18px;
  font-weight: 500;
  transition: 200ms ease;
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
}

.text-danger {
  display: block;
  margin-bottom: 15px;
  color: #f4511e;
  font-size: 15px;
  font-weight: 500;
}
</style>
