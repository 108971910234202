<template>
  <div class="bottom">
    <router-link to="/sogl"> Пользовательское соглашение </router-link>
    <a :href="link" target="_blank"> Поддержка </a>
  </div>
</template>

<style lang="scss" scoped>
.bottom {
  a {
    margin-right: 15px;
    font-size: 14px;
    color: #a3afc9;
    &:hover {
      color: black;
    }
  }
}
</style>

<script>
export default {
  data() {
    return {
      link: process.env.VUE_APP_SUPPORT_LINK || "https://google.com",
    };
  },
};
</script>
