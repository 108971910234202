<template>
  <div class="socials">
    <button class="vk" @click="login('vk')">
      <img alt="vk" src="@/assets/icons/auth/vk.svg" />
    </button>
    <a class="tg" :href="tg" target="_blank">
      <img alt="tg" src="@/assets/icons/auth/tg.svg" />
    </a>
    <!-- <button class="facebook">
      <img alt="facebook" src="@/assets/icons/auth/facebook.svg" />
    </button>
    <button class="steam">
      <img alt="steam" src="@/assets/icons/auth/steam.svg" />
    </button>
    <button class="google">
      <img alt="google" src="@/assets/icons/auth/google.svg" />
    </button> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      tg: "https://t.me/" + process.env.VUE_APP_TG_BOT,
    };
  },
  methods: {
    login(provider) {
      this.$http
        .post("auth/social", { provider })
        .catch(({ data }) => (window.location.href = data.response.redirect));
    },
  },
};
</script>

<style lang="scss" scoped>
// Вход через социальные сети
.socials {
  display: flex;
  // justify-content: space-between;
  button,
  a {
    width: 60px;
    height: 60px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    margin: 15px 10px 15px 0;
    justify-content: center;
    transition: 200ms ease;
    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }
    &.tg {
      background: radial-gradient(
        39.48% 39.48% at 39.48% 59.83%,
        #34bdff 0%,
        #1b9fda 100%
      );
    }
    &.vk {
      background: radial-gradient(50% 50% at 50% 50%, #6cb1ff 0%, #2787f5 100%);
    }
    &.facebook {
      background: radial-gradient(50% 50% at 50% 50%, #739aeb 0%, #466ab7 100%);
    }
    &.steam {
      background: radial-gradient(50% 50% at 50% 50%, #576875 0%, #32424f 100%);
    }
    &.google {
      background: radial-gradient(50% 50% at 50% 50%, #ff8d69 0%, #f4511e 100%);
    }
  }
}
</style>
