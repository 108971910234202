<template>
  <div>
    <div class="box">
      <div v-if="userBalance.count" class="wallet">
        <div class="amount">
          <img class="coin" alt="coin" src="@/assets/icons/header-coin.png" />
          <animated-number
            :value="userBalance.balance"
            :formatValue="(value) => value.toFixed(2)"
            :duration="300"
          />
          <span class="clickable" @click="toggle">
            {{ userBalance.icon }}
            <img alt="arrow-down" src="@/assets/icons/arrow-down-gray.svg" />
          </span>
        </div>
        <router-link
          to="/payments/deposit"
          class="button-wallet"
          :class="{ newyear: theme === 'newyear' }"
        >
          <span class="name">Кошелек</span>
          <span class="icon">
            <img alt="wallet-white" src="@/assets/icons/wallet-white.svg" />
          </span>
        </router-link>
      </div>
      <transition name="fade">
        <div v-if="show" class="banner" v-click-outside="toggle">
          <div
            v-for="balance in avaliableBalances"
            :key="balance.name"
            class="wallet-choice"
            :class="{ active: balance.name === userBalance.active }"
            @click="change(balance.name)"
          >
            <span class="badge">
              {{ balance.icon }}
            </span>
            {{ balance.balance }}
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import vClickOutside from "v-click-outside";
import AnimatedNumber from "animated-number-vue";

export default {
  computed: {
    ...mapGetters("user", {
      userBalance: "getBalance",
      avaliableBalances: "getAvaliableBalances",
    }),
    ...mapState(["theme"]),
  },
  data() {
    return {
      show: false,
    };
  },
  components: {
    AnimatedNumber,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  methods: {
    toggle() {
      this.show = !this.show;
    },
    change(balance) {
      this.$store.commit("user/setActiveBalance", balance);
      this.show = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  position: relative;
  .wallet {
    background-color: #fff;
    border-radius: 6px;
    height: 50px;
    display: flex;
    margin-right: 15px;
    align-items: center;
    justify-content: space-between;
    .amount {
      display: flex;
      align-items: center;
      padding: 0 15px;
      font-size: 17px;
      font-weight: 500;
      img.coin {
        margin-right: 10px;
        width: 20px;
        object-fit: contain;
      }
      span.clickable {
        margin-left: 10px;
        background: rgba(214, 220, 232, 0.25);
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px 8px;
        pointer-events: none;
        &.clickable {
          pointer-events: unset;
          transition: 200ms ease;
          &:hover {
            cursor: pointer;
            opacity: 0.8;
          }
        }
        img {
          margin-left: 5px;
        }
      }
    }
    a.button-wallet {
      background-color: #0085ff;
      color: #ffffff;
      &.newyear {
        background-image: url("~@/assets/icons/btn-pattern-transparent 1.png");
        &::after {
          content: "";
          background-image: url("~@/assets/icons/wallet-button-pattern.png");
          position: absolute;
          left: 0;
          top: -20px;
          right: 0;
          width: 100%;
          height: 40px;
          background-size: cover;
        }
      }
      height: 50px;
      border-radius: 6px;
      display: flex;
      align-items: center;
      width: 150px;
      position: relative;
      @media (max-width: 768px) {
        width: 50px;
      }
      span.name {
        width: calc(100% - 50px);
        text-align: center;
        font-weight: 500;
        font-size: 15px;
        @media (max-width: 768px) {
          display: none;
        }
      }
      span.icon {
        background-color: #0873f8;
        width: 50px;
        height: 50px;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      transition: 200ms ease;
      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
    }
  }
  .banner {
    width: 150px;
    top: 50px + 15px;
    padding: 15px;
    right: 180px;
    position: absolute;
    background-color: white;
    box-shadow: 0 0 30px 3px rgb(226 227 243 / 66%);
    border: 1px solid #ebeff4;
    border-radius: 8px;
    z-index: 1;
    .wallet-choice {
      border: 1px solid #f3f3fb;
      border-radius: 6px;
      padding: 7px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      color: #a7a7bf;
      &:last-child {
        margin-bottom: 0px;
      }
      .badge {
        width: 24px;
        height: 24px;
        border-radius: 3px;
        background: #0085ff;
        box-shadow: 0px 4px 4px rgba(233, 233, 253, 0.08);
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        margin-right: 10px;
        // font-weight: 500;
        font-size: 14px;
      }
      &.active .badge {
        box-shadow: 0px 4px 4px rgba(233, 233, 253, 0.08);
        background: #64cc7a;
        pointer-events: none;
      }
      transition: 200ms ease;
      &:hover {
        opacity: 0.8;
        cursor: pointer;
      }
    }
  }
}
</style>
