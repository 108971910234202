import store from "@/store";

export default [
  {
    path: "/auth/social",
    component: () => import("@/views/Auth/Social.vue"),
  },
  {
    path: "/2fa",
    component: () => import("@/views/2fa.vue"),
  },
  {
    path: "/logout",
    name: "Logout",
    beforeEnter(to, from, next) {
      next("/");
      store.commit("user/authenticate");
    },
  },
];
