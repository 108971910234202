import Vue from "vue";
import VueRouter from "vue-router";
import profileRoutes from "./profile";
import gamesRoutes from "./games";
import paymentsRoutes from "./payments";
import authRoutes from "./auth";
import isUserLoadedMiddleware from "@/middlewares/isUserLoaded";

Vue.use(VueRouter);

const routes = [
  // Главная страница
  {
    path: "/",
    component: () => import("@/views/Home.vue"),
  },
  ...authRoutes,
  ...gamesRoutes,
  profileRoutes,
  paymentsRoutes,
  {
    path: "/r/:code",
    beforeEnter(to, from, next) {
      localStorage.setItem("refCode", to.params.code);
      next("/");
    },
  },
  {
    path: "/faq",
    component: () => import("@/views/FAQ.vue"),
  },
  {
    path: "/sogl",
    component: () => import("@/views/Sogl.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(isUserLoadedMiddleware);

export default router;
