<template>
  <div class="header">
    <Menu />
    <div v-if="isLoggedIn" class="right">
      <Logotype />
      <Social />
      <Wallet />
      <!-- <Notification /> -->
      <Profile />
    </div>
    <div v-else class="right">
      <Logotype />
      <Social />
      <Auth />
    </div>
  </div>
</template>

<script>
import Menu from "./Menu";
import Social from "./Social";
import Wallet from "./Wallet";
import Logotype from "./Logotype";
// import Notification from "./Notification";
import Profile from "./Profile";
import Auth from "./Auth";
import { mapGetters } from "vuex";

export default {
  computed: mapGetters("user", ["isLoggedIn"]),
  components: {
    Menu,
    Social,
    Wallet,
    Logotype,
    // Notification,
    Profile,
    Auth,
  },
};
</script>

<style lang="scss" scoped>
.header {
  border-bottom: 1px solid #dde1ef;
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
  align-items: center;
  .right {
    display: flex;
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: space-between;
    .right {
      width: 100%;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 15px;
    }
  }
}
</style>
