<template>
  <div class="flex">
    <router-link to="/profile" class="profile">
      <img v-if="user.avatar" :alt="user.name" :src="url + user.avatar" />
      <div class="info">
        <div class="hello">Привет,</div>
        <div class="name">{{ user.name }}</div>
      </div>
    </router-link>
    <router-link class="button" to="/profile/settings">
      <img alt="Настройки" src="@/assets/icons/union.svg" />
    </router-link>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: mapState("user", ["user"]),
  data() {
    return {
      broadcast: null,
      url: this.CDN_URL,
    };
  },
  destroyed() {
    if (this.broadcast !== null) this.broadcast.unsubscribe();
  },
  mounted() {
    this.broadcast = this.$centrifuge.subscribe(
      "usr#" + this.user.id,
      ({ data }) => {
        if (data.notify)
          this.$toast.open({
            type: data.notify.type,
            message: data.notify.content,
          });

        if (data.balances) this.$store.commit("user/balance", data.balances);

        if (data["x100.winModal"])
          this.$store.commit("x100/setData", data["x100.winModal"]);
      }
    );
  },
};
</script>

<style lang="scss" scoped>
.flex {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .profile {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    img {
      width: 50px;
      height: 50px;
      border-radius: 6px;
      margin-right: 15px;
    }
    .info {
      width: 150px;
      .hello {
        font-size: 14px;
        color: #9191a0;
      }
      .name {
        font-weight: bold;
        color: #3a3e46;
      }
    }
  }
  .button {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    background-color: #fff;
  }

  @media (max-width: 768px) {
    .profile {
      .info {
        display: none;
      }
    }
    .button {
      display: none;
    }
  }
}
</style>
