export default {
  namespaced: true,
  state: {
    notifications: [
      { title: "Almost done!", description: "Complete registration of your" },
      { title: "hello!", description: "World" },
    ],
  },
  mutations: {
    deleteNoty(state, id) {
      state.notifications.splice(id, 1);
    },
  },
};
