import Vue from "vue";
import router from "@/router";
import Fingerprint2 from "@fingerprintjs/fingerprintjs";

export default {
  namespaced: true,
  state: {
    isLoaded: false,
    walletModal: false,
    course: {
      rub: 100,
      usd: 100,
      euro: 100,
      demo: 100,
    },
    icons: {
      rub: "₽",
      usd: "$",
      euro: "€",
      demo: "₽",
    },
    balance: localStorage.getItem("userBalance") || "rub",
    user: null,
  },
  mutations: {
    // Авторизация пользователя в приложении
    authenticate(state, data = {}) {
      if (data.access_token) {
        if (data.user) state.user = data.user;
        localStorage.setItem("access_token", data.access_token);
        Vue.http.headers.common["Authorization"] =
          "Bearer " + data.access_token;
      } else {
        state.user = null;
        localStorage.removeItem("access_token");
        Vue.http.headers.common["Authorization"] = null;
      }
    },

    walletModalUpdate(state, status) {
      state.walletModal = status;
    },

    // Обновление
    // update(state, data) {
    //   state.user = {
    //     ...state.user,
    //     ...data,
    //   };
    // },

    balance(state, balances) {
      state.user.balances = {
        ...state.user.balances,
        ...balances,
      };
    },

    setActiveBalance(state, balance) {
      localStorage.setItem("userBalance", balance);
      state.balance = balance;
    },
  },
  actions: {
    async login({ commit }, { access_token, user }) {
      if (!access_token) return false;
      Vue.http.headers.common["Authorization"] = "Bearer " + access_token;
      try {
        if (!user) {
          let { data } = await Vue.http.get("auth/me");
          user = data.response;
          let balance = localStorage.getItem("userBalance") || "rub";
          if (!user.balances[balance])
            commit("setActiveBalance", Object.keys(user.balances)[0]);
        }
        commit("authenticate", { access_token, user });
      } catch (error) {
        if (error?.status === 403) {
          localStorage.setItem("access_token", access_token);
          router.push("/2fa");
          return false;
        }
        if (error?.status === 401) commit("authenticate");
      }
    },
    async getFingerprint() {
      return await new Promise((resolve) =>
        Fingerprint2.getV18((value, components) => {
          components.push({ key: "hwid", value });
          resolve(Buffer.from(JSON.stringify(components)).toString("base64"));
        })
      );
    },
  },
  getters: {
    convertAmount:
      ({ course }) =>
      (amount, currency, decimal = true) => {
        amount = Number(amount);
        if (decimal) return Number((amount / course[currency]).toFixed(2));
        else return Number((amount * course[currency]).toFixed(2));
      },
    isLoggedIn({ user }) {
      return !!user;
    },
    getRank({ user }) {
      return user?.rank?.lvl || 0;
    },
    getAvaliableBalances({ user, icons }, { convertAmount }) {
      return Object.entries(user.balances).map(([name, balance]) => {
        return {
          icon: icons[name],
          name,
          balance: convertAmount(balance, name),
        };
      });
    },
    getBalance({ user, balance, icons, course }, { convertAmount }) {
      return {
        active: balance,
        balance: user ? convertAmount(user.balances[balance], balance) : 0,
        icon: icons[balance],
        count: user ? Object.keys(user.balances).length : 0,
        course: user ? course[balance] : 100,
      };
    },
  },
};
