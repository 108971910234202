export default [
  {
    path: "/dice",
    redirect: () => "/dice/classic",
  },
  {
    path: "/dice/classic",
    component: () => import("@/views/Dice/Classic.vue"),
  },
  // {
  //   path: "/dice/bonus",
  //   component: () => import("@/views/Dice/Bonus.vue"),
  // },
];
