import authMiddleware from "@/middlewares/auth";

export default {
  path: "/payments",
  component: () => import("@/views/Payments/Index.vue"),
  beforeEnter: authMiddleware,
  children: [
    {
      path: "",
      redirect: () => "/payments/deposit",
    },
    {
      path: "deposit",
      component: () => import("@/views/Payments/Deposit.vue"),
    },
    {
      path: "withdraw",
      component: () => import("@/views/Payments/Withdraw.vue"),
    },
  ],
};
