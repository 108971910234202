import store from "../store";

export default async (to, from, next) => {
  if (store.state.user.isLoaded) return next();
  await store.dispatch("user/login", {
    access_token: localStorage.getItem("access_token"),
  });
  store.state.user.isLoaded = true;
  return next();
};
