export default {
  namespaced: true,
  state: {
    winModal: false,
    data: {},
  },
  mutations: {
    showModal(state, data) {
      state.winModal = data;
    },
    setData(state, data) {
      state.data = data;
      state.winModal = true;
    },
  },
};
