<template>
  <div>
    <button @click="show = true">
      <span class="name">Авторизация</span>
      <span class="icon">
        <img alt="wallet-white" src="@/assets/icons/wallet-white.svg" />
      </span>
    </button>
    <transition name="fade" tag="div">
      <Modal v-if="show" @close="show = false" />
    </transition>
  </div>
</template>

<script>
import Modal from "@/components/Auth/Modal";

export default {
  data() {
    return {
      show: false,
    };
  },
  components: {
    Modal,
  },
};
</script>

<style lang="scss" scoped>
button {
  background-color: #0085ff;
  color: #ffffff;
  height: 50px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  width: 200px;
  span.name {
    width: calc(100% - 50px);
    text-align: center;
    font-weight: 500;
    font-size: 15px;
  }
  span.icon {
    background-color: rgba(#115def, 0.45);
    width: 50px;
    height: 50px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  transition: 200ms ease;
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}
</style>
