import authMiddleware from "@/middlewares/auth";

export default {
  path: "/profile",
  component: () => import("@/views/Profile/Index.vue"),
  beforeEnter: authMiddleware,
  children: [
    {
      path: "",
      redirect: () => "/profile/games",
    },
    {
      path: "games",
      component: () => import("@/views/Profile/Games/Index.vue"),
      children: [
        {
          path: "",
          redirect: () => "/profile/games/dice",
        },
        {
          path: "dice",
          component: () => import("@/views/Profile/Games/Dice.vue"),
        },
        {
          path: "x100",
          component: () => import("@/views/Profile/Games/X100.vue"),
        },
      ],
    },
    {
      path: "payments",
      component: () => import("@/views/Profile/Payments/Index.vue"),
      children: [
        {
          path: "",
          redirect: () => "/profile/payments/deposits",
        },
        {
          path: "deposits",
          component: () => import("@/views/Profile/Payments/Deposits.vue"),
        },
        {
          path: "withdraws",
          component: () => import("@/views/Profile/Payments/Withdraws.vue"),
        },
      ],
    },
    {
      path: "bonuses",
      component: () => import("@/views/Profile/Bonuses/Index.vue"),
      children: [
        {
          path: "",
          redirect: () => "/profile/bonuses/promo",
        },
        {
          path: "promo",
          component: () => import("@/views/Profile/Bonuses/Promo.vue"),
        },
        {
          path: "bonuses",
          component: () => import("@/views/Profile/Bonuses/Bonuses.vue"),
        },
      ],
    },
    {
      path: "settings",
      component: () => import("@/views/Profile/Settings.vue"),
    },
    {
      path: "security",
      component: () => import("@/views/Profile/Security.vue"),
    },
    {
      path: "ranks",
      component: () => import("@/views/Profile/Ranks.vue"),
    },
    {
      path: "referals",
      component: () => import("@/views/Profile/Referals.vue"),
    },
    {
      path: "verify/:code",
      component: () => import("@/views/Profile/Verify.vue"),
    },
  ],
};
