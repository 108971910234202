<template>
  <ul>
    <li>
      <router-link to="/">Главная</router-link>
    </li>
    <li v-if="isLoggedIn">
      <router-link
        to="/profile/bonuses"
        active-class="router-link-exact-active"
      >
        Бонусы
      </router-link>
    </li>
    <li v-if="isLoggedIn">
      <router-link to="/profile/referals">Рефералы</router-link>
    </li>
    <li>
      <router-link to="/faq">FAQ</router-link>
    </li>
    <li>
      <a :href="link" target="_blank">Поддержка</a>
    </li>
    <!--
    <li>
      <router-link to="/contacts">Контакты</router-link>
    </li> -->
  </ul>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: mapGetters("user", ["isLoggedIn"]),
  data() {
    return {
      link: process.env.VUE_APP_SUPPORT_LINK || "https://google.com",
    };
  },
};
</script>

<style lang="scss" scoped>
ul {
  display: flex;
  @media (max-width: 768px) {
    display: none;
  }
  li {
    margin-right: 30px;
    &:last-child {
      margin-right: 0;
    }
    a {
      display: block;
      padding: 35px 0;
      font-weight: 500;
      font-size: 15px;
      color: #a3afc9;
      text-transform: uppercase;
      transition: 200ms ease;
      border-bottom: 3px solid transparent;
      &.router-link-exact-active,
      &:hover {
        border-color: #0085ff;
        color: #3a3e46;
      }
    }
  }
}
</style>
