<template>
  <div class="sidebar">
    <router-link to="/" class="logotype">
      <img alt="Bazar" src="@/assets/images/logotype.png" />
    </router-link>

    <div class="center">
      <ul class="menu">
        <li>
          <router-link to="/dice" :class="{ newyear: theme === 'newyear' }">
            <img alt="dice" src="@/assets/icons/dice-gray.svg" />
            <span>Dice</span>
          </router-link>
        </li>

        <li>
          <router-link to="/x100" :class="{ newyear: theme === 'newyear' }">
            <img alt="coin" src="@/assets/icons/roulette-gray.svg" />
            <span>x100</span>
          </router-link>
        </li>
      </ul>
    </div>
    <div class="bottom">
      <span class="newyear">
        <img
          v-if="theme === 'newyear'"
          alt="on"
          src="@/assets/icons/snow-mode-on.svg"
          @click="changeTheme('default')"
        />
        <img
          v-else
          alt="off"
          src="@/assets/icons/snow-mode-off.svg"
          @click="changeTheme('newyear')"
        />
      </span>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  computed: mapState(["theme"]),
  methods: mapMutations(["changeTheme"]),
};
</script>

<style lang="scss" scoped>
.sidebar {
  width: 80px;
  background-color: white;
  position: fixed;
  left: 0;
  z-index: 1;
  bottom: 0;
  top: 0;

  @media (max-width: 768px) {
    display: none;
  }
  a.logotype {
    display: block;
    text-align: center;
    margin: 25px 0;
    img {
      width: 45px;
    }
  }
  .center {
    height: calc(100% - 52px - (25px * 2) - 70px);
    // 100% ширина - ширина лого - отступы у лого
    display: flex;
    flex-direction: column;
    justify-content: center;
    ul {
      li {
        a {
          width: 60px;
          height: 60px;
          margin: auto auto 15px auto;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 6px;
          span {
            position: absolute;
            display: none;
            left: 75px;
            bottom: 0;
            top: 0;
            margin: auto;
            height: 35px;
            align-items: center;
            justify-content: center;
            background-color: #0085ff;
            color: white;
            box-shadow: 0px 15px 35px rgba(69, 99, 255, 0.25);
            font-weight: 500;
            font-size: 15px;
            padding: 10px 20px;
            border-radius: 6px;
          }
          &.newyear {
            &::after {
              content: "";
              background-image: url("~@/assets/icons/button-snow.png");
              position: absolute;
              left: 0;
              top: -10px;
              right: 0;
              width: 100%;
              opacity: 0;
              height: 35px;
              background-size: cover;
            }
            span::after {
              content: "";
              position: absolute;
              left: -7px;
              top: 0;
              bottom: 0;
              width: 20px;
              height: 10px;
              background-size: contain;
              display: block;
              margin: auto;
              background-image: url("~@/assets/icons/sidebar-polygon.svg");
            }
          }
          &.router-link-active {
            pointer-events: none;
            box-shadow: 0px 15px 35px rgba(69, 99, 255, 0.25);
            background-color: #0085ff;
            &::after {
              opacity: 1;
            }
            &.newyear {
              background-image: url("~@/assets/icons/btn-pattern-transparent 1.png");
            }
            img {
              filter: brightness(2);
            }
          }
          transition: 200ms ease;
          &:hover {
            box-shadow: 0px 15px 35px rgba(69, 99, 255, 0.25);
            background-color: #0085ff;
            &.newyear {
              background-image: url("~@/assets/icons/btn-pattern-transparent 1.png");
            }
            img {
              filter: brightness(2);
            }
            span {
              display: flex;
            }
            &::after {
              opacity: 1;
            }
          }
        }
        &:last-child a {
          margin-bottom: 0;
        }
      }
    }
  }
  .bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    span.newyear {
      img {
        &:hover {
          opacity: 0.7;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
