<template>
  <div class="socials">
    <a :href="tg" class="tg">
      <img alt="tg" src="@/assets/icons/auth/tg.svg" />
    </a>
    <a :href="vk" target="_blank" class="vk">
      <img alt="vk" src="@/assets/icons/auth/vk.svg" />
    </a>
  </div>
</template>

<script>
export default {
  data() {
    return {
      vk: process.env.VUE_APP_VK_GROUP,
      tg: process.env.VUE_APP_TG_GROUP,
    };
  },
};
</script>

<style lang="scss" scoped>
.socials {
  display: flex;
  margin-right: 20px;
  @media (max-width: 768px) {
    display: none;
  }
  a {
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    // border: 1px solid rgba(#cbd3e2, 0.45);
    border-radius: 6px;
    img {
      width: 20px;
      object-fit: contain;
    }
    &.tg {
      background: radial-gradient(
        39.48% 39.48% at 39.48% 59.83%,
        #34bdff 0%,
        #1b9fda 100%
      );
    }
    &.vk {
      background: radial-gradient(50% 50% at 50% 50%, #6cb1ff 0%, #2787f5 100%);
    }
    transition: 200ms ease;
    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }
  }
}
</style>
