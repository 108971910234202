import Vue from "vue";
import Vuex from "vuex";
import notifications from "./notifications";
import user from "./user";
import x100 from "./x100";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    theme: localStorage.getItem("theme") || "newyear",
  },
  mutations: {
    changeTheme(state, name) {
      state.theme = name;
      localStorage.setItem("theme", name);
    },
  },
  actions: {},
  getters: {},
  modules: { notifications, user, x100 },
});
