<template>
  <div :class="{ newyear_bg: theme === 'newyear' }">
    <Sidebar />
    <vue-confirm-dialog />
    <div class="layout__content">
      <Header />
      <transition name="fade">
        <router-view />
      </transition>
      <Bottom />
    </div>
  </div>
</template>

<script>
import Sidebar from "@/components/Sidebar";
import Header from "@/components/Header/Index";
import Bottom from "@/components/Bottom";
import { mapState } from "vuex";
export default {
  components: {
    Sidebar,
    Header,
    Bottom,
  },
  computed: mapState(["theme"]),
};
</script>

<style lang="scss">
* {
  border: 0;
  outline: 0;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Rubik", sans-serif;
}

html,
body {
  background-color: #f5f6fa;
}

body {
  overflow-y: scroll;
}

ul,
li {
  list-style: none;
}

a {
  text-decoration: none;
}

.newyear_bg {
  background-image: url("~@/assets/icons/background-pattern.png");
  background-position: top;
}

.layout__content {
  width: 1280px + 80px;
  margin: 0 auto;
  padding: 0 0 0 80px; // левый sidebar
  @media (max-width: 768px) {
    width: 100%;
    padding: 10px;
  }
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: #f5f6fa;
  -webkit-border-radius: 6px;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 6px;
  border-radius: 6px;
  background: #d1d5e3;
}

::-webkit-scrollbar-thumb:window-inactive {
  background: #d1d5e3;
}

.fade-enter-active {
  transition: opacity 0.5s;
}
.fade-enter {
  opacity: 0;
}
.fade-leave-to {
  display: none;
}
</style>
