<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div v-click-outside="onClickOutside" class="container">
        <img
          alt="close"
          class="close"
          src="@/assets/icons/close-modal.svg"
          @click="onClickOutside"
        />
        <div class="first-section">
          <div class="message">
            <div class="badge">
              <img alt="gift" src="@/assets/icons/gift-white.svg" />
            </div>
            Новогодние бонусы и подарки при регистрации!
          </div>
          <img
            class="logo"
            alt="Bazar"
            src="@/assets/images/logotype-horizontal-white.png"
          />
          <div class="footer">Bazar &copy; 2021.</div>
        </div>
        <div class="two-section">
          <Tab
            v-if="tab !== 'forget'"
            :tab="tab"
            @login="tab = 'login'"
            @register="tab = 'register'"
          />
          <Login v-if="tab === 'login'" />
          <Register v-else-if="tab === 'register'" />
          <Forget v-else @back="tab = 'login'" />
          <div v-if="tab !== 'forget'">
            <More />
            <Socials />
            <Bottom @forget="tab = 'forget'" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Tab from "./Tab";
import Login from "./Login";
import Register from "./Register";
import Forget from "./Forget";
import More from "./More";
import Socials from "./Socials";
import Bottom from "./Bottom";
import vClickOutside from "v-click-outside";

export default {
  data() {
    return {
      tab: "login",
    };
  },
  components: {
    Tab,
    Login,
    Register,
    Forget,
    More,
    Socials,
    Bottom,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  methods: {
    onClickOutside() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
    .container {
      width: 930px;
      margin: 0px auto;
      background-color: #f5f6fa;
      border-radius: 34px;
      display: flex;
      position: relative;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
      img.close {
        position: absolute;
        right: 25px;
        top: 35px;
        transition: 200ms ease;
        &:hover {
          cursor: pointer;
          opacity: 0.7;
        }
      }
      .first-section {
        width: 450px;
        background-image: url("~@/assets/images/auth-modal-bg.png");
        background-size: cover;
        border-top-left-radius: 30px;
        padding: 30px 30px 45px 30px;
        flex-direction: column;
        display: flex;
        justify-content: space-between;
        border-bottom-left-radius: 30px;
        .message {
          border-radius: 12px;
          background-color: white;
          display: flex;
          align-items: center;
          padding: 12px;
          color: #3a3e46;
          font-size: 15px;
          font-weight: 500;
          .badge {
            width: 45px;
            height: 45px;
            border-radius: 8px;
            display: flex;
            margin-right: 12px;
            align-items: center;
            justify-content: center;
            background: radial-gradient(
              50% 50% at 50% 50%,
              #b8e160 0%,
              #8aad3e 100%
            );
            box-shadow: 0px 5px 15px rgba(89, 116, 33, 0.45);
          }
        }
        img.logo {
          margin: 0 auto;
          display: block;
        }
        .footer {
          text-align: center;
          color: white;
          font-size: 16px;
        }
      }
      .two-section {
        padding: 45px 50px;
        width: calc(100% - 450px);
      }

      @media (max-width: 768px) {
        width: 100%;
        flex-wrap: wrap;
        border-radius: 0;
        overflow-y: auto;
        height: 100%;
        .first-section {
          width: 100%;
          img.logo,
          .footer {
            display: none;
          }
          border-radius: 0;
        }
        .two-section {
          width: 100%;
        }
      }
    }
  }
}
</style>
