import dice from "./dice";

export default [
  ...dice,
  {
    path: "/x100",
    component: () => import("@/views/x100.vue"),
  },
  // {
  //   path: "/crazytime",
  //   component: () => import("@/views/CrazyTime.vue"),
  // },
  // {
  //   path: "/megawheel",
  //   component: () => import("@/views/MegaWheel.vue"),
  // },
  // {
  //   path: "/xray",
  //   component: () => import("@/views/xray.vue"),
  // },
];
