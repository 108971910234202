<template>
  <div class="tabs">
    <span :class="{ active: tab === 'login' }" @click="$emit('login')"
      >Вход</span
    >
    <span :class="{ active: tab === 'register' }" @click="$emit('register')"
      >Регистрация</span
    >
  </div>
</template>

<script>
export default {
  props: {
    tab: String,
  },
};
</script>

<style lang="scss" scoped>
// Переключения вкладок
.tabs {
  height: 60px;
  border-radius: 6px;
  display: flex;
  margin-bottom: 15px;
  border: 1px solid #d8d6d6;
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    font-weight: 600;
    color: #3a3e46;
    text-transform: uppercase;
    font-size: 14px;
    transition: 200ms ease;
    &.active {
      background: #0085ff;
      border-radius: 6px;
      color: white;
      pointer-events: none;
    }
    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }
}
</style>
